<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1 uk-flex uk-flex-middle">
      <div class="uk-margin-right">
        <img
          :src="`${images}/icon/ic_arrow_left_black.svg`"
          class="cursor-pointer"
          alt=""
          @click.prevent="$router.push({ name: 'PulpingFacility'})"
        >
      </div>
      <div>
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top-medium base-card">
      <facility-info 
        :id="id"
        :images="images"
        :loading-detail="loadingDetail"
        :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
        :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      />
    </div>
    <div class="uk-card uk-card-default uk-margin-medium-top base-card">
      <h4 class="uk-heading-line">
        Plantation
      </h4>
      <table-plantation
        :params="paramsTable"
      />
    </div>
    <modal-add-edit-discard
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalDelete"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { notificationSuccess } from '@/utils/notification'
import { PREFIX_IMAGE } from '@/utils/constant'
import getHeaderTitle from '@/utils/header-title'
import FacilityInfo from './Detail'
import TablePlantation from '@/components/globals/table/facility/master-table-facility-list/plantation'
import ModalAddEditDiscard from '@/components/globals/modals/ModalAddEditDiscard'

export default {
  components: {
    FacilityInfo,
    TablePlantation,
    ModalAddEditDiscard
  },
  data() {
    return {
      id: this.$route.params.id,
      images: PREFIX_IMAGE,
      loadingDetail: true,
      paramsTable: {
        pulping_facility_id: this.$route.params.id
      }
    }
  },
  computed: {
    ...mapGetters({
      getDataModalAddEdit: 'pulpingFacility/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'pulpingFacility/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    this.loadingDetail
    await this.actionDetailPulping(this.id)
    this.loadingDetail = false
  },
  methods: {
    ...mapActions({
      actionDetailPulping: 'pulpingFacility/getDetailPulping',
      actionDeletePulping: 'pulpingFacility/deletePulping'
    }),
    ...mapMutations({
      setDataModalAddEdit: 'pulpingFacility/SET_DATA_MODAL_ADD_EDIT',
      setToggleModalAddEditDiscard: 'pulpingFacility/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    handleModalDelete(){
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        loadingTable: false,
        loadingPopUp: true
      })
      this.actionDeletePulping(this.id).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.setToggleModalAddEditDiscard,
              loadingTable: true,
              loadingPopUp: false
            })
            notificationSuccess('Delete Pulping Facility Successful')
          }, 500)
          setTimeout(() => {
            this.$router.push({ name: 'PulpingFacility' })
            this.setToggleModalAddEditDiscard({
              ...this.setToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
          }, 1000)
        }
      })
    }
  }
}
</script>
