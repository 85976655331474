<template>
  <div>
    <h4 class="uk-heading-line">
      Facility Info
    </h4>
    <ul
      uk-tab
    >
      <li class="uk-active">
        <a href="#">Info Details</a>
      </li>
      <li>
        <a href="#">App Access</a>
      </li>
    </ul>

    <ul class="uk-switcher uk-margin">
      <li class="uk-active">
        <div
          v-if="loadingDetail"
          class="uk-flex uk-flex-center uk-flex-middle"
        >
          <div uk-spinner />
        </div>
        <div
          v-else
          class="uk-form-stacked"
        >
          <div
            class="uk-child-width-expand@s uk-grid-medium"
            uk-grid
          >
            <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
              <div
                class="uk-child-width-expand@s uk-grid-medium"
                uk-grid
              >
                <div class="uk-inline uk-width-1-1">
                  <label class="uk-form-label">
                    VCP Code
                  </label>
                  <div class="uk-form-controls">
                    <input
                      name="vcp_code"
                      :value="detailPulping.vcp_code || '-'"
                      class="uk-input readonly"
                      type="text"
                      autocomplete="off"
                      readonly
                    >
                  </div>
                </div>
                <div class="uk-inline uk-width-1-1">
                  <label class="uk-form-label">
                    Export Hub
                  </label>
                  <div class="uk-form-controls">
                    <input
                      name="export_hub"
                      :value="detailPulping.export_hub.name || '-'"
                      class="uk-input readonly"
                      type="text"
                      autocomplete="off"
                      readonly
                    >
                  </div>
                </div>

                <div class="uk-inline uk-width-1-1">
                  <label class="uk-form-label">
                    Farmer Name
                  </label>
                  <div class="uk-form-controls">
                    <input
                      name="partner_name"
                      :value="detailPulping.partner || '-'"
                      class="uk-input readonly"
                      type="text"
                      autocomplete="off"
                      readonly
                    >
                  </div>
                </div>

                <div class="uk-inline uk-width-1-1">
                  <label class="uk-form-label">
                    Latitude
                  </label>
                  <div class="uk-form-controls">
                    <input
                      name="latitude"
                      :value="detailPulping.latitude || '-'"
                      class="uk-input readonly"
                      type="text"
                      autocomplete="off"
                      readonly
                    >
                  </div>
                </div>
              </div>
            </div>
          
            <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
              <div
                class="uk-child-width-expand@s uk-grid-medium"
                uk-grid
              >
                <div class="uk-inline uk-width-1-1">
                  <label class="uk-form-label">
                    Facility Name
                  </label>
                  <div class="uk-form-controls">
                    <input
                      name="facility_name"
                      :value="detailPulping.facility_name || '-'"
                      class="uk-input readonly"
                      type="text"
                      autocomplete="off"
                      readonly
                    >
                  </div>
                </div>

                <div class="uk-inline uk-width-1-1">
                  <label class="uk-form-label">
                    Hulling Facility
                  </label>
                  <div class="uk-form-controls">
                    <input
                      name="hulling_facility"
                      :value="detailPulping.hulling_facility.facility_name || '-'"
                      class="uk-input readonly"
                      type="text"
                      autocomplete="off"
                      readonly
                    >
                  </div>
                </div>

                <div class="uk-inline uk-width-1-1">
                  <label class="uk-form-label">
                    Address
                  </label>
                  <div class="uk-form-controls">
                    <input
                      name="address"
                      :value="detailPulping.address || '-'"
                      class="uk-input readonly"
                      type="text"
                      autocomplete="off"
                      readonly
                    >
                  </div>
                </div>

                <div class="uk-inline uk-width-1-1">
                  <label class="uk-form-label">
                    Longitude
                  </label>
                  <div class="uk-form-controls">
                    <input
                      name="longitude"
                      :value="detailPulping.longitude || '-'"
                      class="uk-input readonly"
                      type="text"
                      autocomplete="off"
                      readonly
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
              <div
                class="uk-child-width-expand@s uk-grid-medium"
                uk-grid
              >
                <div class="uk-inline uk-width-1-1">
                  <label class="uk-form-label">
                    Status
                  </label>
                  <div v-if="detailPulping.status === 1">
                    <label-status
                      status="active"
                      :label="`${status.ACTIVE}`"
                      table="false"
                    />
                  </div>
                  <div v-else-if="detailPulping.status === 0">
                    <label-status
                      status="inactive"
                      :label="`${status.INACTIVE}`"
                      table="false"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-margin-medium-top">
              <h5 class="uk-heading-line">
                Data Bak
              </h5>
              <div class="uk-overflow-auto border-table">
                <table
                  class="uk-table uk-table-divider uk-table-middle uk-margin-small-bottom"
                  aria-describedby="bucket-table"
                >
                  <thead class="thead-table-varion">
                    <tr>
                      <th class="uk-width-small">
                        No
                      </th>
                      <th class="uk-table-expand">
                        Name
                      </th>
                    </tr>
                  </thead>
                  <template v-if="loadingDetail">
                    <loading-table :colspan="10" />
                  </template>
                  <template v-else-if="Object.keys(detailPulping?.pulping_bucket).length > 0">
                    <tbody>
                      <tr
                        v-for="(content, index) in detailPulping?.pulping_bucket"
                        :key="index"
                      >
                        <td>{{ index+1 }}</td>
                        <td>{{ content.bucket_name }}</td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-else>
                    <empty-table 
                      :colspan="10" 
                    />
                  </template>
                </table>
              </div>
            </div>

            <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-margin-medium-top">
              <h5 class="uk-heading-line">
                Data Lapang Jemur
              </h5>
              <div class="uk-overflow-auto border-table">
                <table
                  class="uk-table uk-table-divider uk-table-middle uk-margin-small-bottom"
                  aria-describedby="drying-field-table"
                >
                  <thead class="thead-table-varion">
                    <tr>
                      <th class="uk-width-small">
                        No
                      </th>
                      <th class="uk-table-expand">
                        type
                      </th>
                      <th class="uk-table-expand">
                        Name
                      </th>
                    </tr>
                  </thead>
                  <template v-if="loadingDetail">
                    <loading-table :colspan="10" />
                  </template>
                  <template v-else-if="Object.keys(detailPulping?.pulping_drying_field).length > 0">
                    <tbody>
                      <tr
                        v-for="(content, index) in detailPulping?.pulping_drying_field"
                        :key="index"
                      >
                        <td>{{ index+1 }}</td>
                        <td>{{ content.drying_field_type?.name || '-' }}</td>
                        <td>{{ content.drying_field_name || '-' }}</td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-else>
                    <empty-table 
                      :colspan="10" 
                    />
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li>
        <div class="uk-margin-top">
          <div class="uk-overflow-auto border-table">
            <table
              class="uk-table uk-table-divider uk-table-middle"
              aria-describedby="app-access-table"
            >
              <thead class="thead-table-varion">
                <tr>
                  <th class="uk-table-expand">
                    Menu Name
                  </th>
                  <th class="uk-width-small" />
                </tr>
              </thead>
              <template v-if="loadingDetail">
                <loading-table :colspan="10" />
              </template>
              <template v-else-if="Object.keys(detailPulping?.pulping_app_access).length > 0">
                <tbody>
                  <tr>
                    <td>Terima Gabah</td>
                    <td>
                      <span v-if="detailPulping.pulping_app_access?.recipient === true">
                        <img
                          :src="`${images}/icon/ic_checklist_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                      <span v-else>
                        <img
                          :src="`${images}/icon/ic_close_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Sortasi</td>
                    <td>
                      <span v-if="detailPulping.pulping_app_access?.sorter === true">
                        <img
                          :src="`${images}/icon/ic_checklist_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                      <span v-else>
                        <img
                          :src="`${images}/icon/ic_close_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Pengupasan</td>
                    <td>
                      <span v-if="detailPulping.pulping_app_access?.peeler === true">
                        <img
                          :src="`${images}/icon/ic_checklist_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                      <span v-else>
                        <img
                          :src="`${images}/icon/ic_close_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Fermentasi</td>
                    <td>
                      <span v-if="detailPulping.pulping_app_access?.fermenter === true">
                        <img
                          :src="`${images}/icon/ic_checklist_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                      <span v-else>
                        <img
                          :src="`${images}/icon/ic_close_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Pencucian</td>
                    <td>
                      <span v-if="detailPulping.pulping_app_access?.washer === true">
                        <img
                          :src="`${images}/icon/ic_checklist_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                      <span v-else>
                        <img
                          :src="`${images}/icon/ic_close_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Pengeringan</td>
                    <td>
                      <span v-if="detailPulping.pulping_app_access?.dryer === true">
                        <img
                          :src="`${images}/icon/ic_checklist_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                      <span v-else>
                        <img
                          :src="`${images}/icon/ic_close_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Pengepakan</td>
                    <td>
                      <span v-if="detailPulping.pulping_app_access?.courier === true">
                        <img
                          :src="`${images}/icon/ic_checklist_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                      <span v-else>
                        <img
                          :src="`${images}/icon/ic_close_table.svg`"
                          alt=""
                          loading="lazy"
                        >
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-else>
                <empty-table 
                  :colspan="10" 
                />
              </template>
            </table>
          </div>
        </div>
      </li>
    </ul>

    <div class="uk-flex uk-flex-right uk-margin-medium-top">
      <button
        class="uk-button red uk-margin-right"
        :class="isCanAccessUser('delete', 'PULPING FACILITY') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('delete', 'PULPING FACILITY')"
        @click="showDeleteConfirmModal"
      >
        <img
          :src="`${images}/icon/ic_delete_red.svg`"
          alt="delete"
          loading="lazy"
          class="uk-margin-small-right"
        >
        Delete
      </button>
      <button
        class="uk-button green"
        :class="isCanAccessUser('edit', 'PULPING FACILITY') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('edit', 'PULPING FACILITY')"
        @click.prevent="$router.push({ name: 'PulpingFacilityEdit'})"
      >
        <img
          :src="`${images}/icon/ic_edit_white.svg`"
          alt="edit"
          loading="lazy"
          class="uk-margin-small-right"
        >
        Edit
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LabelStatus from '@/components/globals/LabelStatus'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { STATUS } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    LabelStatus,
    EmptyTable,
    LoadingTable
  },
  props: {
    id: {
      required: true,
      type: String
    },
    images: {
      required: true,
      type: String
    },
    loadingDetail: {
      required: false,
      type: Boolean
    },
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  },
  data() {
    return{
      status: STATUS
    }
  },
  computed: {
    ...mapGetters({
      detailPulping: 'pulpingFacility/detail_pulping'
    })
  },
  methods:{
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    showDeleteConfirmModal() {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        targetModals: open,
        title: 'Delete Confirmation',
        description: 'Are you sure want to delete this data?',
        customTitleBtnOk: 'Delete',
        customTitleBtnCancel: 'Cancel',
        type: 'delete',
        loadingTable: false,
        loadingPopUp: false,
        id: this.id
      })
    }
  }
}
</script>

